type ExtendedWindow = Window & typeof globalThis & {
	plausible: any
	_wsq: any
}
const win = <ExtendedWindow>window;
win.plausible = win.plausible || function() { (win.plausible.q = win.plausible.q || []).push(arguments) };



(() => {
	const _wsq = win._wsq = win._wsq || [];
	_wsq.push(function () {
		// @ts-ignore
		_setNom('millecheck');
		// @ts-ignore
		_wysistat();
	});
	const ws = document.createElement('script'); ws.src = 'https://www.wysistat.com/ws.jsa'; ws.async = true; document.head.append(ws);
})();
